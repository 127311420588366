import { render, staticRenderFns } from "./bureauAdd.vue?vue&type=template&id=8124bf52"
import script from "./bureauAdd.vue?vue&type=script&lang=js"
export * from "./bureauAdd.vue?vue&type=script&lang=js"
import style0 from "./bureauAdd.vue?vue&type=style&index=0&id=8124bf52&prod&media=screen&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports